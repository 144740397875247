var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ayqqsy2FG8dpChp_CQSih"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable @typescript-eslint/no-var-requires */
import * as Sentry from '@sentry/nextjs';
const { version } = require('./package.json');

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const env =
  process.env.NODE_ENV === 'production' ? 'production' : 'development';

Sentry.init({
  dsn: env === 'production' ? SENTRY_DSN : undefined,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  sampleRate: 0.25,
  environment: env,
  release: version,
});
